/**
 * QUESTION.TSX
 * Question to display in a card
 */
import { useState } from "react"
import { slideInUp, flipInY, slideInLeft } from 'react-animations'
import { StyleSheet, css } from 'aphrodite'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect } from "react-redux"
import config from "../config"
import { store } from ".."
import Button from "./button"
import Color from "color";
import { detect } from 'detect-browser';
import resetScroller from "../utils/reset-scroller"
import ReactMarkdown from 'react-markdown'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck, faChevronLeft, faChevronRight, faShieldAlt } from "@fortawesome/free-solid-svg-icons"
import { Session } from "model/session.model"
import { Topic } from "model/topic.model"
import { Question } from "model/question.model"
import { cancelFullscreen, editDone, editMessage, editNoteAndOpenNextQuestion, editResponse, openNextQuestion, resetSecondary } from "redux/actions"

const QUESTIONS_LISI = [
  "8e9ada52-86bd-432f-ad47-13067f8140df", //Paris
  "18daab5b-385f-4e02-bd29-31801077e2ef" //Saint brieuc
]

interface StateProps extends WithTranslation{
  session : Session
}

interface OwnProps{
  isFullScreen : boolean
  isMobile : boolean
  topic : Topic
  question : Question
}

type Props = StateProps & OwnProps

function QuestionComponent(props:Props) {
  const { t } = props

  //Detect safari
  const browser = detect()
  const isSafari = browser ? config.alternativeDisplayBrowsers.indexOf(browser.name) > -1 : false

  //Get axis color
  const axisColor = props.topic.Axis.color

  //Display text on hover
  //For likert affirmations
  const [hoverCircle, setHoverCircle] = useState<number | null>(null)

  //Reset fullscreen question
  function cancel(){
    resetScroller()
    store.dispatch(cancelFullscreen(props.topic.id))
  }

  //Get color for every item of likert
  function getColor(i:number){
    if (props.session.templateOptions.emojiMode){

      /*if (props.question.reverse){
        i = 4 - i
      }*/

      switch(i){
        case 0:
          return "#eca49a"
        case 1:
          return "#eccf9a"
        case 2:
          return "#b0eacf"
        case 3:
          return "#71deaa"
        case 4:
          return "#20CA7E"
        default:
          return "#b5b5b5"
      }

    }else{
      const ratio = (4 - i) * 0.25
      var color = Color(axisColor ? axisColor : "#757575")
      color = color.mix(Color("#b5b5b5"), ratio)
      return color.hex()
    }
  }

  //Color for the NPS scale
  function getColorNps(i){
    if (props.question.reverse) i = 11 - i
    if (i >= 9){
      return "#20CA7E"
    }else if (i >= 7){
      return "#f2d600"
    }else{
      return "#eb5a46"
    }
  }

  //If last question of the last topic
  //Display button "ending"
  function getConfirmButton(){
    return t(
      props.session.topicsDone.length + 1 === props.session.topics.length &&
      props.topic.Questions.reverse()[0]?.id === props.question.id
      ?
      "survey_submit"
      :
      "question_confirm"
    )
  }

  //Get calculated value for an answer for scale (4 or 5 responses)
  function getNoteForScale(i){
    if (props.question.responseCount === 4){

      const items = [
        { i : 0, value : 0 },
        { i : 1, value : 25 },
        { i : 2, value : 75 },
        { i : 3, value : 100 },
      ]

      const item = items.find(x => x.i === i)
      return item ? item.value : 0

    }else{
      return i * 25
    }
  }
  
  //Get style if draft
  function getStyle(){
    var style:any = {}

    if (props.question.draft){
      style.background = "linear-gradient(45deg, white 30%, #f2d600 30%, #f2d600 70%, white 70%)"
    }

    return style
  }
  //Is label visible
  //Display visible on hover with the mouse
  //Or
  //> show first and last option if user have not already vote
  //> show given value if user have already vote
  function isLabelVisible(index){
    if (props.question.done){
      return (
        hoverCircle === index ||
        props.question.note === (props.question.reverse ? 100 - (index * 25) : (index * 25))
      )
    }else{
      return (
        hoverCircle === index ||
        index === 0 || 
        index === props.question.responseCount - 1
      )
    }
  }

  //Cancel specific secondary question
  function reset(){
    resetScroller()
    store.dispatch(resetSecondary(props.question.id))
  }

  //Set response for choice
  //If special set note for it
  function setResponse(response){
    var valueSpecial:null|number = null

    if (response.special){
      valueSpecial = response.special === "psychological-job-demands" ? 3 : 2
    }

    store.dispatch(editResponse(props.topic.id, props.question.id, response.id, valueSpecial))

  }

  //Vote for a question
  function setNote(note){

    //Reverse values for emojiMode
    if (props.session.templateOptions.emojiMode && props.question.reverse){
      note = 100 - note
    }

    //Hide scroller
    resetScroller()

    //Update note and detect next question to open
    store.dispatch(editNoteAndOpenNextQuestion(props.topic.id, props.question.id, note))

  }

  //Click on next button when choice or open type
  function clickNext(){
    var goNext = true

    //If type === choice
    //> detect if there is a maximum for the number of anwser
    if (props.question.choiceCount && props.question.type === "choice"){
      if (props.question.activePropositions.length > props.question.choiceCount){
        goNext = false

        if (props.question.choiceCount === 1){
          alert(t("question_choice_limit_1"))
        }else{
          alert(t("question_choice_limit_many", {
            count : props.question.choiceCount
          }))
        }

      }

    }

    if (goNext){
      store.dispatch(openNextQuestion(props.topic.id, props.question.id))
      resetScroller()
    }

  }

  //Click on card already done to edit again
  function unlockBranch(){
    store.dispatch(editDone(props.topic.id))
  }

  //Render open question
  function _renderOpenQuestion(){
    return (
      QUESTIONS_LISI.indexOf(props.question.id) > -1 ?
      <div className="flex">

        <div className="flex1">

          { props.question.id === "8e9ada52-86bd-432f-ad47-13067f8140df" &&
          <img src={require("assets/lisi-paris.png").default}
            alt="paris"
            width="100%"
          />
          }

          { props.question.id === "18daab5b-385f-4e02-bd29-31801077e2ef" &&
          <img src={require("assets/lisi-saint-brieuc.png").default}
            alt="saint-brieuc"
            width="100%"
          />
          }

        </div>

        <div className="flex1 question-open">
          <textarea value={props.question.message}
            onChange={(e) => store.dispatch(editMessage(props.topic.id, props.question.id, e.target.value))}
            placeholder="Classement des points qui vous semblent les plus importants (Par exemple : A, E, C [...])">
          </textarea>
        </div>
        
      </div>
      :
      <div className="question-open">
        <textarea value={props.question.message}
          onChange={(e) => store.dispatch(editMessage(props.topic.id, props.question.id, e.target.value))}
          placeholder={t("question_open_label")}>
        </textarea>
        <div className="question-open-help">
          <p style={{ color : '#a7a7a7' }}>
            {props.session.getResponseLabel(100, "message", "question_open_help")}
          </p>
        </div>
      </div>
    )
  }

  //Render content of the question
  function _renderQuestionContent(){
    
    //Type choice : list of propositions
    if (props.question.type === "choice"){
      console.log(props.session.templateOptions)
      return (
        <div className={"flex1 question-propositions" + (props.question.Propositions.length >= 10 ? " question-propositions-sm" : "")}>

          { props.question.Propositions.map(proposition =>
          <div key={proposition.id} 
            className="flex question-proposition">

            <div className="_hover question-proposition-circle question-proposition-circle-choice"
              style={{ borderColor : axisColor }}
              onClick={() => setResponse(proposition)}>
              { proposition.active &&
              <div className="question-proposition-circle-center"
                style={{ backgroundColor : axisColor }}>
              </div>
              }
            </div>

            <div className="flex1 flex">
              <span>
                { proposition.id === "others"
                ?
                (
                  props.session.templateOptions["response10000Label"]
                  ?
                  props.session.templateOptions["response10000Label__" + props.session.language]
                  :
                  t("others")
                )
                :
                <div style={{margin: "-12px 0"}}>
                  <ReactMarkdown children={proposition.name[props.session.language]}
                    linkTarget="_blank"
                  />
                </div>
                }
              </span>
            </div>

          </div>
          )
          }

          { props.question.Propositions.find(x => x.id === "others" && x.active) &&
          _renderOpenQuestion()
          }

        </div>
      )
    } 
    //Type open : free comment
    else if (props.question.type === "open"){
      return _renderOpenQuestion()
    }
    //Type scale
    else{
      return (
        <div className="flex"
          style={{ margin : 'auto 0px' }}>
          <div className="flex1"/>

          <div style={{
              width : '100%',
              maxWidth : '500px'
            }}>
            

            { props.session.templateOptions.emojiMode &&
            <div className="flex"
              style={{
                fontSize: 38,
                margin: "-8px 16px 12px 16px"
              }}>
              {props.session.templateOptions.responseEmojiLabel_no_fr}
              <div className="flex1"/>
              {props.session.templateOptions.responseEmojiLabel_yes_fr}
            </div>
            }

            { //Type scale with two answer (boolean type)
            props.question.responseCount === 2 &&
            <div className="flex question-boolean-container">

              <div className="flex1 flex">

                <div className="_hover question-boolean"
                  onClick={() => setNote(0)}
                  style={{ 
                    borderColor : props.question.done && (props.question.note === (props.question.reverse ? 100 : 0))
                    ? axisColor : "#b5b5b5"
                  }}>
                  <div className="question-boolean-center flex"
                    style={{ 
                      backgroundColor : props.question.done && (props.question.note === (props.question.reverse ? 100 : 0))
                      ? axisColor : "#b5b5b5" 
                    }}>
                    <span>{props.session.getResponseLabel(2, "no", "utils")}</span>
                  </div>
                </div>

              </div>

              <div className="flex1 flex">

                <div className="_hover question-boolean"
                  onClick={() => setNote(100)}
                  style={{ 
                    borderColor : props.question.done && (props.question.note === (props.question.reverse ? 0 : 100))
                    ? axisColor : "#b5b5b5" 
                  }}>
                  <div className="question-boolean-center flex"
                    style={{ 
                      backgroundColor : props.question.done && (props.question.note === (props.question.reverse ? 0 : 100))
                      ? axisColor : "#b5b5b5" 
                    }}>
                    <span>{props.session.getResponseLabel(2, "yes", "utils")}</span>
                  </div>
                </div>

              </div>

            </div>
            }

            { /** Case when question is type nps (10 values) */
            props.question.responseCount === 10 &&
            <div>

              <div className="flex question-nps-label">
                {props.session.getResponseLabel(10, (props.question.reverse ? "happy" : "unhappy"), "question_nps")}
                <div className="flex1 question-nps-separator"/>
                <FontAwesomeIcon icon={faChevronRight} className="question-nps-chevron"/>
                {props.session.getResponseLabel(10, (props.question.reverse ? "unhappy" : "happy"), "question_nps")}
              </div>
              
              <div className="flex question-nps-container">
                <div className="flex1"/>
                {[...Array(10)].map((e, i) =>
                <div className={(i + 1) * 10 === props.question.note ? 
                    "_hover question-nps question-nps-active flex" : 
                    "_hover question-nps flex"
                  }
                  key={i}
                  onClick={() => setNote((i + 1) * 10)}
                  style={{
                    backgroundColor : getColorNps(i + 1),
                    marginTop : (i % 2 > 0) ? "26px" : ""
                  }}>
                  <span>
                    {i + 1}
                  </span>
                </div>
                )
                }
                <div className="flex1"/>
              </div>
            </div>
            }

            { /** case when response count is Likert */
            (props.question.responseCount === 4 || props.question.responseCount === 5) &&
            <div className="flex"
            >
              { [...Array(props.question.responseCount + 1)].map((e, i) => 
              <div key={i} className={
                  i > 0 && i < props.question.responseCount ? "flex1 question-likert" : "question-likert"
                }>

                { (i > 0 && i < props.question.responseCount) &&
                <div className="question-likert-gradient" 
                  style={{ background : 'linear-gradient(to left, ' + getColor(i) + ', ' + getColor(i - 1) + ')' }}>
                </div>
                }

                { i < props.question.responseCount &&
                <div className={
                    props.question.done && (props.question.note === (props.question.reverse ? 100 - getNoteForScale(i) : getNoteForScale(i))) ? 
                    "question-likert-circle question-likert-circle-done" : 
                    "question-likert-circle"
                  }
                  onClick={() => setNote(getNoteForScale(i))}
                  onMouseEnter={() => setHoverCircle(i)} 
                  onMouseLeave={() => setHoverCircle(null)}
                  style={{ backgroundColor : getColor(i) }}>
                </div>
                }

              </div>
              )
              }
            </div>
            }

            { 
            (props.question.responseCount === 4 || props.question.responseCount === 5) &&
            <div className="question-likert-label-container flex"
              style={{ 
                flexDirection : props.question.reverse && props.session.templateOptions.emojiMode ? "row-reverse" : undefined
              }}>

              { isLabelVisible(0)
              ?
              <div className="question-likert-label flex1">
               {props.session.getResponseLabel(5, 1, "question_likert")}
              </div>
              :
              <div className="flex1"></div>
              }

              { props.question.responseCount === 5
              ?
              <div className="flex2 flex">

                { isLabelVisible(1) &&
                <div className="flex1 question-likert-label"
                  style={{ marginLeft : '-4px' }}>
                  {props.session.getResponseLabel(5, 2, "question_likert")}
                </div>
                }
                
                <div className="flex3 question-likert-label">
                  { isLabelVisible(2) && props.session.getResponseLabel(5, 3, "question_likert") }
                </div>

                { isLabelVisible(3) &&
                <div className="flex1 question-likert-label"
                  style={{ marginRight : '-4px' }}>
                  { props.session.getResponseLabel(5, 4, "question_likert") }
                </div>
                }

              </div>
              :
              <div className="flex2 flex">
                { isLabelVisible(1) &&
                <div className="flex1 question-likert-label"
                  style={{ marginLeft : '-4px' }}>
                  {props.session.getResponseLabel(4, 2, "question_likert")}
                </div>
                }
                <div className="flex1 question-likert-label"></div>
                { isLabelVisible(2) &&
                <div className="flex1 question-likert-label"
                  style={{ marginRight : '-4px' }}>
                  {props.session.getResponseLabel(4, 3, "question_likert")}
                </div>
                }
              </div>
              }

              {isLabelVisible(props.question.responseCount - 1) 
              ?
              <div className="question-likert-label flex1">
                {props.session.getResponseLabel(5, 5, "question_likert")}
              </div>
              :
              <div className="flex1"></div>
              }

            </div>
            }

          </div>
          
          <div className="flex1"/>

        </div>
      )
    }
  }

  function _renderQuestionFooter(){
    if (props.question.type !== 'scale'){
      return (
        <div className="question-button">
          <Button backgroundColor={axisColor}
            color="white"
            className="primary"
            onClick={clickNext}>
            { props.question.done 
            ? 
            <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon> 
            :
            getConfirmButton()
            }
          </Button>
        </div>
      )
    }else{
      return (
        <div className="question-nspp">
          { !props.question.QuestionsTopic.disableNoOpinion &&
          <span className="_hover flex" onClick={() => setNote(null)}>
            <div className="flex1" />

            { props.session.templateOptions.showCircle &&
            <div
              className="_hover question-proposition-circle"
              style={{ borderColor : axisColor }}
            >
              {( props.question.note === null && props.question.done ) &&
              <div className="question-proposition-circle-center"
                style={{ backgroundColor : axisColor }}>
              </div>
              }
            </div>
            }

            <div className="question-nspp-text">
              {props.session.getResponseLabel(0, "nspp", "question")}
            </div>
            <div className="flex1" />
          </span>
          }
        </div>
      )
    }
  }

  function _renderQuestionHeader(isFullScreen){

    var className = "flex question-header"
    if (isFullScreen) className += " question-header-fullscreen"

    return (
      <div className={className}>

        <div className="flex1 question-header-chevron flex">
          { props.question.order > 0
          ?
          <FontAwesomeIcon icon={faChevronLeft}
            className="_hover"
            onClick={reset}
          />
          :
          props.topic.fullScreen &&
          <FontAwesomeIcon icon={faChevronLeft}
            className="_hover"
            onClick={cancel}
          />
          }
        </div>

        <div className="question-header-border"></div>

        { props.question.order === 0
        ?
        <div className="question-image">
          { props.topic.Axis.image
          ?
          <img alt="axis"
            src={props.topic.Axis.picture}>
          </img>
          :
          <FontAwesomeIcon icon={faShieldAlt}/>
          }
        </div>
        :
        <div className="question-secondary">
          {t("question_secondary")}
        </div>
        }

        <div className="question-header-border"></div>
        <div className="flex1"></div>
      </div>
    )
  }

  function _renderQuestionName(isFullScreen){

    const questionName = props.question.name[props.session.language]
    const reduceSize:boolean = questionName ? questionName.length > (props.question.type === "open" ? 50 : 100) : false

    var className = "question-name"
    if (isFullScreen) className += " question-name-fullscreen"

    if (props.question.type === 'choice'){
      return (
        <div className={className} style={{ 
            color : props.question.order > 0 ? axisColor : ""
          }}>
          <ReactMarkdown className="_hover"
            children={questionName}
            linkTarget="_blank"
          />
          <p className="question-name-choice">
            { !props.question.choiceCount
            ?
            <span>
              {t("question_choice_no_limit")}
            </span>
            :
            <span>
              { props.question.choiceCount === 1 
              ? 
              t("question_choice_limit_1") : 
              t("question_choice_limit_many", {
                count : props.question.choiceCount
              }) 
              }
            </span>
            }
          </p>
        </div>
      )
    }else{
      className += " flex1 flex"
      return (
        <div className={className}>
          <span style={{ 
              color : props.question.order > 0 ? axisColor : ""
            }}>
            <div className="question-name-big"
              style={{ fontSize : reduceSize ? "1.2em" : "" }}>
              <ReactMarkdown children={questionName}
                linkTarget="_blank"
              />
            </div>
          </span>
        </div>
      )
    }
  }

  function _renderTopicName(isFullScreen){

    var className = "question-topic"
    if (isFullScreen) className += " question-topic-fullscreen"

    if (props.question.order === 0){
      return (
        <div className="flex">
          { !isFullScreen &&
          <div className="flex1"></div>
          }
          <div className={className}>
            {props.topic.name[props.session.language]}
          </div>
          <div className="flex1"></div>
        </div>
      )
    }else{
      return null
    }

  }

  return (
    (props.isFullScreen && !props.isMobile)
    ?
    <div style={getStyle()}
      className={"question flex question-fullscreen " + (
        !props.question.done ?
        css(questionStyle[isSafari ? '' : 'slideInLeft']) :
        null
      )}>
          
      <div className="flex2">

        <div className="question-count">
          {t("question")}
        </div>

        {_renderTopicName(true)}
        {_renderQuestionName(true)}
        {_renderQuestionHeader(true)}

      </div>

      <div className="flex3 flex flex-dcol"
        style={{ padding : '20px' }}>
        {_renderQuestionContent()}
        {_renderQuestionFooter()}
      </div>

    </div>
    :
    <div style={getStyle()}
      className={"question flex flex-dcol " + (
        !props.question.done ?
        css(questionStyle[props.question.order === 0 ? (props.topic.done ? '' : 'slideInUp') : (isSafari ? '' : 'flipInY')]) :
        null
      )}>

      { props.topic.done &&
      <div className="_hover question-done" onClick={unlockBranch}></div>
      }

      {_renderQuestionHeader(false)}
      {_renderTopicName(false)}
      {_renderQuestionName(false)}
      {_renderQuestionContent()}
      {_renderQuestionFooter()}

    </div>

  )
  
}

const questionStyle = StyleSheet.create({

  slideInUp : {
    animationName : slideInUp,
    animationDuration : "1.5s"
  },

  flipInY : {
    animationName : flipInY,
    animationDuration : "1s"
  },

  slideInLeft : {
    animationName : slideInLeft,
    animationDuration : "1.5s"
  }

})


const mapStateToProps = state => ({
  session : state.reducers
})

export default connect(mapStateToProps)(withTranslation()(QuestionComponent))