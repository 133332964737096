/**
 * TEST-MODAL
 * CTA to go back to the app
 */
import { Session } from "model/session.model"
import { connect } from "react-redux"
import { withTranslation, WithTranslation } from "react-i18next"
import CardButton from "./card-button"
import { faPlay, faTimes } from "@fortawesome/free-solid-svg-icons"
import { store } from "index"
import { closeTestModal } from "redux/actions"

interface StateProps extends WithTranslation{
  session : Session
}

interface OwnProps{
  showAxisCount? : boolean
}

type Props = StateProps & OwnProps

function TestModal(props:Props) {
  const { t } = props

  return (
    <div className="save-modal-container flex">
      <div className="save-modal">
        
        <div className="flex">
          <div className="flex1"/>
          { (props.session.axesCount > 1 && props.showAxisCount) &&
          <p style={{ marginTop : '40px' }}>
            <b>
              {t("test_modal_intro", {
                count : props.session.axesCount,
                countDone : props.session.axesCountDone,
                s : props.session.axesCountDone > 1 ? "s" : ""
              })}
            </b>
          </p>
          }
          <div className="flex1"/>
        </div>

        <div className="flex" style={{ margin : '20px 80px' }}>
          <CardButton onClick={() => store.dispatch(closeTestModal())}
            isCta
            text={t("test_modal_continue")}
            icon={faPlay}
          />
          <CardButton onClick={() => window.close()}
            text={t("test_modal_exit")}
            icon={faTimes}
          />
        </div>

      </div>
    </div>
  )
  
}

const mapStateToProps = state => ({
  session : state.reducers
})

export default connect(mapStateToProps)(withTranslation()(TestModal))