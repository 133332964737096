/**
 * ACTIONS.TS
 */

import { Session } from "model/session.model"
import { ADD_USER_FOR_TEST, BACK, CANCEL_FULLSCREEN, CLOSE_TEST_MODAL, EDIT_CURRENT_AXIS, EDIT_DONE, EDIT_LANGUAGE, EDIT_MESSAGE, EDIT_NOTE, EDIT_RESPONSE, EDIT_STATUS, EDIT_TOPICS, GET_SESSION, INIT_SESSION, OPEN_NEXT_QUESTION, RESET_SECONDARY } from "./constants"


//REDUX

export const addUserForTest = (userId: string) => ({
  type: ADD_USER_FOR_TEST,
  payload: {
    userId
  }
})

export const back = axisId => ({
  type : BACK,
  payload : {
    axisId
  }
})

export const cancelFullscreen = (topicId:string) => ({
  type : CANCEL_FULLSCREEN,
  payload : {
    topicId
  }
})

export const closeTestModal = () => ({
  type : CLOSE_TEST_MODAL
})

export const editCurrentAxis = (axisId:string | null) => ({
  type : EDIT_CURRENT_AXIS,
  payload : {
    axisId
  }
})

export const editDone = (topicId:string) => ({
  type : EDIT_DONE,
  payload : {
    topicId
  }
})

export const editLanguage = (language:string) => ({
  type : EDIT_LANGUAGE,
  payload : {
    language
  }
})

export const editMessage = (topicId:string, questionId:string, message:string) => ({
  type : EDIT_MESSAGE,
  payload : {
    topicId,
    questionId, 
    message
  }
})

export const editNote = (topicId:string, questionId:string, note:number | null) => ({
  type : EDIT_NOTE,
  payload : {
    topicId,
    questionId,
    note
  }
})

export const editResponse = (topicId, questionId, propositionId, special) => ({
  type : EDIT_RESPONSE,
  payload : {
    topicId,
    questionId,
    propositionId,
    special
  }
})

export const editStatus = (status:string) => ({
  type : EDIT_STATUS,
  payload : {
    status
  }
})

export const editTopics = () => ({
  type : EDIT_TOPICS
})

export const getSession = (session:Session) => ({
  type : GET_SESSION,
  payload : {
    session
  }
})

export const initSession = () => ({
  type : INIT_SESSION
})

export const openNextQuestion = (topicId:string, questionId:string) => ({
  type : OPEN_NEXT_QUESTION,
  payload : {
    topicId,
    questionId
  }
})

export const resetSecondary = questionId => ({
  type : RESET_SECONDARY,
  payload : {
    questionId
  }
})

/*********API***********/


/*** ARCHIVE ***/


export const fetchUserSurvey = (resultsCode: string) => ({
  type : 'ARCHIVE',
  payload : {
    url : "/user/survey?results_code=" + resultsCode
  }
})

/*** BACKEND ***/


//_auth

export const _auth = (code: string) => ({
  type : 'BACKEND',
  payload : {
    method : "POST",
    url : "/_auth/quickvote",
    data : {
      code
    }
  }
})

//public

export const fetchFeedbackCheck = (feedbackId:string) => ({
  type : 'BACKEND',
  payload : {
    method : "GET",
    url : "/public/feedback/check",
    data : {
      feedbackId
    }
  }
})

export const fetchMessages = (codes:string) => ({
  type : 'BACKEND',
  payload : {
    method : "GET",
    url : "/public/parameters/messages",
    data : {
      codes
    }
  }
})

export const fetchProject = (projectId:string) => ({
  type : 'BACKEND',
  payload : {
    method : "GET",
    url : "/public/project",
    data : {
      projectId
    }
  }
})

export const fetchResponses = (id: string) => ({
  type : 'BACKEND',
  payload : {
    method : "GET",
    url :  "/public/qv/results",
    data : {
      id
    }
  }
})

export const fetchTemplate = (templateId:string) => ({
  type : 'BACKEND',
  payload : {
    method : "GET",
    url : "/public/template",
    data : {
      templateId
    }
  }
})

export const fetchTopicsPublic = (templateId:string) => ({
  type : 'BACKEND',
  payload : {
    method : "GET",
    url : "/public/topics",
    data : {
      templateId
    }
  }
})


//qv

export const getInvitedUsers = (userIds: string) => ({
  type : 'BACKEND',
  payload : {
    method : "GET",
    url : "/qv/users",
    data : {
      userIds
    }
  }
})

export const invite = (user: any) => ({
  type : 'BACKEND',
  payload : {
    method : "POST",
    url : "/qv/invite",
    data : {
      user
    }
  }
})

export const postFeedback = (feedbackId, topics) => ({
  type : 'BACKEND',
  payload : {
    method : "POST",
    url : "/public/feedback",
    data : {
      feedbackId,
      topics
    }
  }
})

export const postNext = (topics, axisId) => ({
  type : 'BACKEND',
  payload : {
    method : "POST",
    url : "/qv/next",
    data : {
      topics,
      axisId
    }
  }
})


export const postStart = () => ({
  type : 'BACKEND',
  payload : {
    method : "POST",
    url : "/qv/start"
  }
})

//THUNK

export const editNoteAndOpenNextQuestion:any = (topicId, questionId, note) => (dispatch) => {
  dispatch(editNote(topicId, questionId, note))
  dispatch(openNextQuestion(topicId, questionId))
}