//i18n.JS
//Init traductions dans l'application. Utiliser les fichiers json dans langugues pour mettre à jour les textes

//Importer module de traduction
import i18n from "i18next";
import backend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";

//Importer fichiers de traduction
import languagesAr from './languages/ar.json';
import languagesCs from './languages/cs.json';
import languagesDe from './languages/de.json';
import languagesEn from './languages/en.json';
import languagesEs from './languages/es.json';
import languagesFr from './languages/fr.json';
import languagesPl from './languages/pl.json';
import languagesTr from './languages/tr.json';
import languagesZh from './languages/zh.json';

//Init le module
i18n
.use(backend)
.use(initReactI18next)
.init({
  resources: {
    ar: { translation: languagesAr },
    cs: { translation: languagesCs },
    de: { translation: languagesDe },
    en: { translation: languagesEn },
    es: { translation: languagesEs },
    fr: { translation : languagesFr },
    pl: { translation: languagesPl },
    tr: { translation: languagesTr },
    zh: { translation: languagesZh }
  },
  react: {
    useSuspense: false
  },
  debug: false,
  lng: "en",
  fallbackLng: ["en", "fr"],
  interpolation: {
    escapeValue: false
  }
});

export default i18n;