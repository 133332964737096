/** 
 * Loader to display
 */

import { faCircleNotch } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { WithTranslation, withTranslation } from "react-i18next"

interface Props extends WithTranslation{}

function Loader(props:Props) {
  const { t } = props
  return (
    <div className="login-message">
      <div>{t("loading")}</div>
      <FontAwesomeIcon icon={faCircleNotch} spin></FontAwesomeIcon>
    </div>
  )
}

export default withTranslation()(Loader)