/**
 * SAVE-MODAL
 * Send edits to server
 */
import { useEffect } from "react"
import { Session } from "model/session.model"
import { connect } from "react-redux"
import { withTranslation, WithTranslation } from "react-i18next"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons"
import { store } from "index"
import { editTopics, postFeedback, postNext, postStart } from "redux/actions"

interface StateProps extends WithTranslation{
  session : Session
}

interface OwnProps{
  action : string,
  onClose : Function
  onSaved : Function
}

type Props = StateProps & OwnProps

function SaveModal(props:Props) {
  const { t } = props

  //Save data
  useEffect(() => {

    async function save(){
      var response:any = {}

      //Edit list of topic if introduction axis is just done
      if (props.session.currentAxis === "*" && props.action === "next"){
        store.dispatch(editTopics())
      }

      //Display timer (the user should see loading is processing)
      await new Promise(resolve => {
        setTimeout(() => {
          resolve(true)
        }, 200)
      })

      if (props.session.saveResults){
        if (props.action === "start"){
          response = await store.dispatch(postStart())
        }
        if (props.action === "next"){
          response = await store.dispatch(postNext(props.session.topics, props.session.currentAxis))
        }
      }

      if (props.session.feedbackId){
        response = await store.dispatch(postFeedback(props.session.feedbackId, props.session.topics))
      }

      if (response.error){
        props.onClose()
      }else{
        props.onSaved()
      }
      
    }

    save()

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="save-modal-container flex">
      <div className="save-modal flex flex-dcol">
        
        <div className="flex1"/>

        <div>
          { props.action === "start"
          ?
          t("loading")
          :
          t("saving")
          }
        </div>

        <div className="flex">
          <div className="flex1"/>
          <FontAwesomeIcon icon={faCircleNotch} spin/>
          <div className="flex1"/>
        </div>

        <div className="flex1"/>

      </div>
    </div>
  )
  
}

const mapStateToProps = state => ({
  session : state.reducers
})

export default connect(mapStateToProps)(withTranslation()(SaveModal))