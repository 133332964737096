/**
 * INVITATION-MODAL
 * 
 */

import { withTranslation, WithTranslation } from "react-i18next"

interface OwnProps {
  onClose: Function
}

type Props = OwnProps & WithTranslation

function InvitationModal(props: Props) {
  const { t } = props

  return (
    <div className="save-modal-container flex" onClick={()=>props.onClose()}>
      <div
        className="save-modal"
        style={{padding: "20px 30px", maxWidth: 600}}
        onClick={(e: any) => e.stopPropagation() }
      >

        <div className="flex">
          <div className="flex1" />
          <u className="_hover" onClick={()=>props.onClose()}>{t("back")}</u>

        </div>

        <p style={{ marginTop: 10 }}>
          <b>
            {t("code_find")}
          </b>
        </p>

        <div style={{ textAlign: "left" }}>
        {t("code_instructions")}
        </div>
        
        <img src={require("assets/invitation-instructions.png").default}
          alt="instruction"
          width="500px"
          style={{marginTop: 10}}
        />

        <div style={{height: 20}} />

      </div>
    </div>
  )
  
}


export default withTranslation()(InvitationModal)