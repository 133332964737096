/**
 * RESULTS.ROUTE.TSX
 */

import { useEffect, useState } from "react"
import Page from "../components/page"
import { connect } from "react-redux"
import { RouteComponentProps, withRouter } from "react-router-dom";
import { WithTranslation, withTranslation } from 'react-i18next'
import { fetchResponses } from "redux/actions"
import { Session } from "model/session.model"
import { store } from "index";
import AxisDisplayer from "components/axis-displayer";
import Card from "components/card";
import { getImageUrl } from "utils/get-image-url";
import LanguageSelector from "components/language-selector";
import { formatDate } from "utils/format-date.utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHourglassEnd, faHourglassStart, faPaperPlane } from "@fortawesome/free-solid-svg-icons";

interface StateProps extends WithTranslation, RouteComponentProps{
  session : Session
}

type Survey = {
  date_end: Date
  date_start: Date
  id: string
  name: string
  project_id: string
  test: boolean
}

export type Proposition = {
  QuestionId: string
  aid: number
  id: string
  name: any
  special: any
}

type Invitation = {
  aid: number
  date_clicked: Date
  date_created: Date
  date_done: Date
  date_started: Date
  fk_account: string
  fk_user: string
  language : string
  dim_survey: Survey
}

export type DataInfo = {
  surveyId: string
  id: string
  aid: number
  name: any
  type: "choice" | "scale" | "open"
  reverse: boolean,
  responseCount: number
  propositions: Proposition[]
  topicId: string
  topicAid: number
  topicName: any
  axisId: string
  axisDefaultImage: boolean
  axisColor: string
  axisImage: string | null
  axisName: string
  answered: boolean
  message: string | null
  selectedPropositions: number[]
  value: number | null
}


function ResultsRoute(props:StateProps) {
  const { t } = props

  const searchParams: URLSearchParams = new URLSearchParams(window.location.search)

  const id: string | null = searchParams.get("id")

  const [questions, setQuestions] = useState<DataInfo[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [axesOrder, setAxesOrder] = useState<string[]>([])
  const [invitation, setInvitation] = useState<Invitation | null>(null)
  const [project, setProject] = useState<any>(null)
  const [currentLanguage, setCurrentLanguage] = useState<string>("fr")


  useEffect(() => {

    function scoreToScale(score: number, responseCount: number, reverse: boolean): number{
      if(responseCount === 4){
        const values: number[] = [0, 25, 75, 100]
        return reverse ? values.reverse().indexOf(score) : values.indexOf(score)
      }
      else if(responseCount === 10){
        return ( reverse ? (100 - score) / 100 : score / 100 ) * responseCount
      }
      else {
        return ( reverse ? (100 - score) / 100 : score / 100 ) * (responseCount - 1)
      }
    }

    async function load(){
      if(id){
        const response: any = await store.dispatch(fetchResponses(id))
        const results: any | any[] = []//await store.dispatch(fetchUserSurvey(id))

        if(!response.error && !results.error){
          var questionList: DataInfo[] = []
          var axesExcluded: string[] = response.axesOrder

          response.questions.sort((a:DataInfo, b:DataInfo)=>{
            return (a.axisId === b.axisId) ?
              response.topicsOrder.indexOf(a.topicId) - response.topicsOrder.indexOf(b.topicId)
            :
              response.axesOrder.indexOf(a.axisId) - response.axesOrder.indexOf(b.axisId)
          }).forEach((x: DataInfo)=>{
            const result: any = results.find((y: any) => x.aid === y.question_aid)

            if(result){

              if(axesExcluded.includes(x.axisId)){
                axesExcluded = axesExcluded.filter((axisId: string) => axisId !== x.axisId)
              }

              questionList.push({
                ...x,
                answered: true,
                message: result.message,
                selectedPropositions: result.propositions,
                value: scoreToScale(result.value, x.responseCount, x.reverse),
              })
            }
            else{
              questionList.push({
                ...x,
                answered: false,
                message: null,
                selectedPropositions: [],
                value: null,
              })
            }

          })

          setAxesOrder(response.axesOrder.filter((axisId: string) => !axesExcluded.includes(axisId) ))
          setQuestions(questionList)
          setInvitation(response.invitation)
          setProject(response.project)
          setCurrentLanguage(response.project.languages.includes(response.invitation.language) ? response.invitation.language : response.project.languages[0])
        }

        setIsLoading(false)

      }
    }

    load()

  }, [id]);

  return (
    <Page
      tabTitle={t("my_results")}
    >
      { isLoading ?
      <div>{t("loading")}</div>
      :
      <div style={{maxWidth:1000}}>

        <div style={{margin:"0 40px"}}>

          <h1>{t("my_results")}</h1>

          <div>
            {t("your_survey_submission")}
          </div>

        </div>

        { invitation &&
        <Card>
          <div className="flex flex-wrap">
            <img src={getImageUrl(project)} height={100} width={100} alt={invitation.dim_survey.name} />

            <div style={{width: 20}} />

            <div>
              <div style={{fontSize:20}}>{invitation.dim_survey.name}</div>

              <div className="flex flex-wrap" style={{color: "#777", fontSize: 14}}>
        
                <div><FontAwesomeIcon icon={faHourglassStart}/>{" " + formatDate(invitation.dim_survey.date_start, false) + "   -   "}</div>
                <div><FontAwesomeIcon icon={faHourglassEnd}/>{" " + (invitation.dim_survey.date_end ? formatDate(invitation.dim_survey.date_end, false) : t("in_progress"))}</div>

              </div>
            </div>

            <div className="flex1" style={{minWidth:10}} />

            <div style={{fontSize: 14}} className="flex flex-dcol">
              <div>{t("survey_responses_sent")}</div>
              <div>
                <FontAwesomeIcon icon={faPaperPlane}/>
                {" " + formatDate(invitation.date_started, false)}
              </div>

              <div className="flex1"/>

              <LanguageSelector
                language={invitation.language}
                languages={project.languages}
                onChange={(lang: string) => setCurrentLanguage(lang)}
              />

            </div>
            
          </div>

        </Card>
        }

        {questions.length > 0 ?
        <div>

          {questions.some((x: DataInfo) => x.axisId === "*") &&
          <AxisDisplayer language={currentLanguage} questions={questions.filter((x: DataInfo) => x.axisId === "*")} />
          }

          {axesOrder.map((axisId: string)=>
          <div key={axisId}>
            <AxisDisplayer language={currentLanguage} questions={questions.filter((x: DataInfo) => x.axisId === axisId)} />
          </div>
          )}

          {questions.some((x: DataInfo) => !x.axisId) &&
          <AxisDisplayer language={currentLanguage} questions={questions.filter((x: DataInfo) => !x.axisId)} />
          }

        </div>
        :
        <Card>
          {t("error_no_results")}
        </Card>
        }
      </div>
      }
    </Page>
  )
  
}

const mapStateToProps = state => ({
  session : state.reducers
})

export default withRouter(connect(mapStateToProps)(withTranslation()(ResultsRoute)))