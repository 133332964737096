/** 
 * Display error message with image
 */

import { WithTranslation, withTranslation } from "react-i18next"

interface OwnProps extends WithTranslation{
  message : string
}

function ErrorMessage(props:OwnProps) {
  const { t } = props
  return (
    <div style={{ textAlign : 'center' }}>

      <h1>
        {t("login_used_oups")}
      </h1>

      <h2>
        {props.message}
      </h2>

      <img src={require("assets/sherlock.png").default}
        alt="sherlock"
        width="120px"
      />
  
    </div>
  )
}

export default withTranslation()(ErrorMessage)