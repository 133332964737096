/**
 * AXIS.MODEL.TS
 * Axis for a card
 */

import { env } from "index"

export class Axis{
  color : string = "#eb5a46"
  id : string = ""
  image : boolean = false
  defaultImage : boolean = false
  name : any = {}
  
  constructor(axis: Partial<Axis> = {}){
    Object.assign(this, axis)
  }

  get picture():string{
    return env.URL_SPACE.concat((this.defaultImage ? "/_default/" : "/Axes/"), this.image + ".png?" + (new Date().getTime()))
  }

}