/**
 * ENV.JS
 * Prefix environnement variable with REACT_APP
 * Override local variable with process variables
 */

const REACT_APP_URL_APPLICATION = "http://localhost:3000"
const REACT_APP_URL_BACKEND = "http://localhost:5004" //"https://backend-dev-zwjbr.ondigitalocean.app" (dev)
const REACT_APP_URL_REPORTING = /*"http://localhost:5000"*/ "https://dev-reporting.id-tree.com" //(dev)
const REACT_APP_URL_SPACE = "https://idtree-dev.fra1.digitaloceanspaces.com"

module.exports = {
    URL_APPLICATION : process.env.REACT_APP_URL_APPLICATION ? process.env.REACT_APP_URL_APPLICATION : REACT_APP_URL_APPLICATION,
    URL_BACKEND : process.env.REACT_APP_URL_BACKEND ? process.env.REACT_APP_URL_BACKEND : REACT_APP_URL_BACKEND,
    URL_REPORTING : process.env.REACT_APP_URL_REPORTING ? process.env.REACT_APP_URL_REPORTING : REACT_APP_URL_REPORTING,
    URL_SPACE : process.env.REACT_APP_URL_SPACE ? process.env.REACT_APP_URL_SPACE : REACT_APP_URL_SPACE
}
