/**
 * NEXT.ROUTE.TSX
 * Step between two axes
 */
import { useEffect } from "react"
import Button from "../components/button"
import Page from "../components/page"
import { connect } from "react-redux"
import { RouteComponentProps, withRouter } from "react-router-dom";
import { WithTranslation, withTranslation } from 'react-i18next'
import { store } from "index"
import { editCurrentAxis } from "redux/actions"
import { Session } from "model/session.model"
import { useMediaQuery } from 'react-responsive'
import { Axis } from "model/axis.model";
import Card from "components/card"
import TestModal from "components/test-modal";

interface StateProps extends WithTranslation, RouteComponentProps{
  session : Session
}

//Home page to connect
//Call login function to load data, then redirect
function NextRoute(props:StateProps) {
  const { t } = props

  //If mobile set maxWidth for page
  const isMobile = useMediaQuery({ query: '(max-width : 600px)' })

  //Get next axis to display
  const currentAxis = props.session.topicsUndone.length > 0 ? new Axis(props.session.topicsUndone[0].Axis) : new Axis()

  //Redirection on load
  //If all topics have been done, go to end
  //If no axis go to questions without axes
  useEffect(() => {
    if (props.session.topicsUndone.length === 0){
      props.history.push("/end/close")
    }else if (currentAxis.id === ""){
      store.dispatch(editCurrentAxis(null))
      props.history.push("/questions")
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAxis])

  //Go to next page
  function next(){
    store.dispatch(editCurrentAxis(currentAxis.id.length > 0 ? currentAxis.id : null))
    props.history.push("/questions")
  }

  //Close quickvote to finish later
  function later(){
    props.history.push("/end/later")
  }

  return (
    <Page
      tabTitle={t("next_title")}
    >

      { props.session.showTestModal &&
      <TestModal showAxisCount/>
      }

      <div className="flex">

        { !isMobile &&
        <div className="flex flex-dcol next-axis-text">
          <div className="flex1"></div>
          <h3>{t("next_title")}</h3>

          <p>
            {t("next_text")}
          </p>

          <div className="flex1"></div>

          <div className="flex">
            <Button className="link" 
              onClick={later}>
              {t("next_later")}
            </Button>
            <div className="flex1"></div>
          </div>

          <div className="flex1"></div>

        </div>
        }

        <Card maxWidth={400}>
          
          <div className="next-axis-header">
            {t("next_topic")}
            <h2>{currentAxis.name[props.session.language]}</h2>
          </div>

          <div className="next-axis-image flex">
            { currentAxis.image
            ?
            <img alt="axis"
              src={currentAxis.picture}>
            </img>
            :
            <div/>
            }
          </div>

          <Button onClick={next}
            className="primary">
            {t("question_confirm")}
          </Button>

          { isMobile &&
          <div>

            <Button className="link"
              onClick={later}>
              {t("next_later")}
            </Button>

          </div>
          }

        </Card>

      </div>

    </Page>
  )
  
}

const mapStateToProps = state => ({
  session : state.reducers
})

export default withRouter(connect(mapStateToProps)(withTranslation()(NextRoute)))