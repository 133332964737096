/**
 * CONFIG.JS
 * Local configuration
 */
module.exports = {

    //Browser with alternative display
    //Some browser do not display some div, so adapt it
    alternativeDisplayBrowsers : [
        "safari",
        "ios",
        "crios"
    ]
    
}
