/**
 * FEEDBACK.ROUTE.TSX
 * Entrypoint for giving feedback for the tool
 */

import Page from "../components/page"
import { connect } from "react-redux"
import { RouteComponentProps, useParams, withRouter } from "react-router-dom";
import { WithTranslation, withTranslation } from 'react-i18next'
import { fetchFeedbackCheck, getSession } from "redux/actions"
import { Session } from "model/session.model"
import { useEffect, useState } from "react";
import Loader from "components/loader";
import { store } from "index";
import feedbackQuestions from "assets/feedback-questions.json"
import ErrorMessage from "components/error-message";
import { Topic } from "model/topic.model";
import { Question } from "model/question.model";

interface StateProps extends WithTranslation, RouteComponentProps{
  session : Session
}

interface FeedbackRouteParams{
  id : string
}

//Home page to connect
//Call login function to load data, then redirect
function FeedbackRoute(props:StateProps) {
  const { t } = props

  //Get code from params
  const { id } = useParams<FeedbackRouteParams>()

  //Error message to display
  const [errorMessage, setErrorMessage] = useState<null | string>(null)

  //Status of the page (display loader)
  const [isLoading, setIsLoading] = useState(true)

  //Get feedback on load
  useEffect(() => {

    load()

    async function load(){
      
      const feedback:any = await store.dispatch(fetchFeedbackCheck(id))
      setIsLoading(false)
      
      if (feedback.error){
        setErrorMessage(t("error_" + feedback.error))
      }else{

        //Init topics
        const topics:Topic[] = []
        feedbackQuestions.forEach((item, i) => {
          const question = new Question(item)
          const topic = new Topic()
          topic.aid = i
          topic.id = question.id
          topic.Questions = [question]
          topics.push(new Topic(topic))
        })

        //Init session
        const session: Session = new Session({
          feedbackId : id,
          language : "fr",
          topics
        })

        //Save session
        store.dispatch(getSession(session))

        //Go to questions
        props.history.push("/questions")

      }

    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  return (
    <Page tabTitle={t("feedback")}>

      { isLoading &&
      <Loader/>
      }

      { errorMessage &&
      <ErrorMessage message={errorMessage}/>
      }

    </Page>
  )
  
}

const mapStateToProps = state => ({
  session : state.reducers
})

export default withRouter(connect(mapStateToProps)(withTranslation()(FeedbackRoute)))