/** 
 * TEXT-INPUT
 */

import { faTimesCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

interface OwnProps{
  autoFocus? : boolean //Is focus auto
  disabled? : boolean //Is text input disabled
  error? : boolean //Red outline if error
  id? : string //Id of text input
  onBlur? : Function //Action to trigger when i lose focus (save for exemple)
  onChange? : Function //Action to trigger when i edit text
  onHelp? : Function //Trigger action on click on info
  onKeyDown? : Function //Action when i click on specific key ("Enter" for exemple)
  placeholder? : string //Placeholder for input
  required? : boolean //If no value or empty string red outline
  title? : string //Label just above input
  type? : InputTypes //Type of text input
  value : any //Text value to display
  values? : string[] //List of values you can pick for text input
  warning? : string //Orange outline if warning
  width? : number //Set defined width
}

type Props = OwnProps
type InputTypes = "datetime-local" | "date" | "password" | "search" | "email" | "number" | "text"

function TextInput(props: Props) {

  function getClassName(){
    var className = 'text-input'
    if (props.error) className = className + ' text-input-error'
    return className
  }

  //Action to trigger when i loose focus
  function handleBlur(){
    if (props.onBlur){

      props.onBlur()

      setTimeout(() => {
      }, 2000)

    }
  }

  //Action onChange text
  function handleChange(event){
    var value = event.target.value
    if (props.onChange){
      props.onChange({
        id : event.target.id,
        value
      })
    }
  }

  //Action on keyDown
  function handleKeyDown(event){
    if (props.onKeyDown){
      props.onKeyDown(event.key)
    }
  }

  return (
    <div className={getClassName()}
      style={{ 
        width : props.width ? props.width + 'px' : ""
      }}>

      { props.title &&
      <div className="text-input-title flex">
        <span className={"grey-t"}>
          { props.title }
        </span>
        <div className="flex1"/>
      </div>
      }

      <div className="flex rel">

        <input
          placeholder={props.placeholder}
          disabled={props.disabled}
          required={props.required}
          id={props.id}
          value={props.value?props.value:""}
          onBlur={ handleBlur }
          onChange={ handleChange }
          onKeyDown={ handleKeyDown }
          type={props.type}
          autoFocus={props.autoFocus}>
        </input>

        <div className="flex1" />

        { props.error &&
        <div className="text-input-check red-t flex">
          <FontAwesomeIcon icon={faTimesCircle}/>
        </div>
        }

      </div>

    </div>

  )
}

export default TextInput