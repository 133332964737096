/**
 * FAQ.ROUTE.TSX
 * Questions about IDTree and QVT in general
 */

import { useState } from "react"
import { connect } from "react-redux"
import { WithTranslation, withTranslation } from 'react-i18next'
import Button from "../components/button"
import { RouteComponentProps, withRouter } from 'react-router'
import Page from "../components/page"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretDown, faCaretRight } from "@fortawesome/free-solid-svg-icons"
import { Session } from "model/session.model"
import ReactMarkdown from 'react-markdown'

interface StateProps extends WithTranslation, RouteComponentProps{
  session : Session
}

function Faq(props:StateProps) {
  const { t } = props
  
  //List of sections 
  const [privacyOpen, setPrivacyOpen] = useState(false)
  const [profilesOpen, setProfilesOpen] = useState(false)
  const [faqOpen, setFaqOpen] = useState(false)

  return (
    <Page tabTitle={t("faq_overview_title")}>
      <div className="faq-container">

        <div className="faq-header">
          <Button className="primary"
            onClick={() => props.history.push('/intro')}>
            {t("launch")}
          </Button>
        </div>

        { props.session.messageOptions.isInfoCustom
        ?
        <div>
          <ReactMarkdown children={props.session.messageOptions.infoContent[props.session.language]}
            linkTarget="_blank"
          />
          <div style={{ minHeight : 100 }}/>
        </div>
        :
        <div>

          <div className="faq-section">

            <div className="faq-section-header flex"
              onClick={() => setPrivacyOpen(!privacyOpen)}>
              <FontAwesomeIcon icon={privacyOpen ? faCaretDown : faCaretRight}></FontAwesomeIcon>
              {t("faq_privacy_title")}
            </div>

            { privacyOpen &&
            <div className="faq-section-open">
              
              <p>
                <b>{t("faq_privacy_text_1")}</b>
              </p>

              <p>
                {t("faq_privacy_text_2")}
              </p>

              <p>
                {t("faq_privacy_text_3", { count : props.session.participationMin })}
              </p>

            </div>
            }

          </div>

          <div className="faq-section">

            <div className="faq-section-header flex"
              onClick={() => setProfilesOpen(!profilesOpen)}>
              <FontAwesomeIcon icon={profilesOpen ? faCaretDown : faCaretRight}></FontAwesomeIcon>
              {t("faq_profile_title")}
            </div>

            { profilesOpen &&
            <div className="faq-section-open">
              
              <p>
                {t("faq_profile_text_1")}
              </p>

              <ul>
                  <li><b>{t("faq_profile_text_2a")}</b> : {t("faq_profile_text_2b")}</li>
                  <li><b>{t("faq_profile_text_3a")}</b> : {t("faq_profile_text_3b")}</li>
              </ul>

            </div>
            }

          </div>

          <div className="faq-section">

            <div className="faq-section-header flex"
              onClick={() => setFaqOpen(!faqOpen)}>
              <FontAwesomeIcon icon={faqOpen ? faCaretDown : faCaretRight}></FontAwesomeIcon>
              {t("faq_rgpd")}
            </div>

            { faqOpen &&
            <div className="faq-section-open">
              
              <p>
                <i>{t("faq_rgpd_0")}</i>
              </p>
              
              <p><b>{t("faq_rgpd_0_1")}</b></p>

              <p>
                {t("faq_rgpd_1")}
              </p>

              <p>
                {t("faq_rgpd_2")}
              </p>

              <p>
                {t("faq_rgpd_3")}
              </p>

              <p>
                {t("faq_rgpd_4")}
              </p>

              <p>
                {t("faq_rgpd_5")}
              </p>

              <p>
                {t("faq_rgpd_6")}
              </p>

              <p>
                {t("faq_rgpd_7")}
              </p>

              <ul>
                <li>{t("faq_rgpd_8_1")}</li>
                <li>{t("faq_rgpd_8_2")}</li>
                <li>{t("faq_rgpd_8_3")}</li>
                <li>{t("faq_rgpd_8_4")}</li>
                <li>{t("faq_rgpd_8_5")}</li>
              </ul>
              
              <p>
                {t("faq_rgpd_9")}
              </p>

              <p>
                {t("faq_rgpd_10")}
              </p> 
              
              <p>
                {t("faq_rgpd_11")}
              </p>

              <p>
                <li>{t("faq_rgpd_11_1")}</li>
              </p>

              <p>
                {t("faq_rgpd_12")}
              </p>
              
            </div>
            }

          </div>
          
          <div style={{ height : '60px' }}/>

        </div>
        }

      </div>
    </Page>

  )
  
}

const mapStateToProps = state => ({
  session : state.reducers
})

export default withRouter(connect(mapStateToProps)(withTranslation()(Faq)))