/**
 * QUESTIONS.ROUTE.TSX
 * Display questions (3 in destkop, 1 in mobile)
 */
import { useEffect, useState } from "react"
import Page from "../components/page"
import { connect } from "react-redux"
import { StyleSheet, css } from 'aphrodite'
import { fadeIn } from 'react-animations'
import { WithTranslation, withTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import resetScroller from "../utils/reset-scroller"
import { RouteComponentProps, withRouter } from 'react-router'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons"
import { Session } from "model/session.model"
import { Topic } from "model/topic.model"
import QuestionComponent from "components/question"
import { store } from "index"
import { back } from "redux/actions"
import SaveModal from "components/save-modal"
import { Question } from "model/question.model"

interface StateProps extends WithTranslation, RouteComponentProps{
  session : Session
}

const MODAL_NEXT = "MODAL_NEXT"

function QuestionsRoute(props:StateProps) {
  const { t } = props

  //Adapt display is screen is mobile or minimized
  const isMobile = useMediaQuery({ query: '(max-width : 600px)' })

  //Display dialog
  const [currentModal, setCurrentModal] = useState<string | null>(null)

  //List of topics to display
  const [currentTopics, setCurrentTopics] = useState<Topic[]>([])

  //On load reset scroller
  useEffect(() => {
    resetScroller()
  }, [])

  //Every load of the page or change of display > reload topics
  //Load only questions not already voted
  useEffect(() => {
    
    //Get all topics for the current axis
    var topics = props.session.topics.filter(x => x.AxisId === props.session.currentAxis)
    
    //If mobile (one topic per page) > select first card not done
    //Else search the 3 first one
    if (isMobile){
      topics = topics.filter(x => !x.done)
    }else{
      const doneBranchesCount = topics.filter(x => x.done).length
      const step = Math.floor(doneBranchesCount / 3) * 3
      topics = topics.slice(step, step + 3)
    }

    //Si there are topics not done display them
    //Else check if there are topics not already done
    //> if true go to "next" page
    //> else go to end
    if (topics.some((x: Topic) => !x.done)){

      //force every question as visible until first question undone (included)
      const index: number = topics.findIndex((topic: Topic) => !topic.done)

      for(let i=0; i<=index; i++){
        topics[i].Questions[0].visible = true
      }

      setCurrentTopics(isMobile ? [topics[0]] : topics)
    } else {
      setCurrentModal(MODAL_NEXT)
    }

  }, [
    isMobile, 
    props.session.topics,
    props.session.currentAxis,
    props.history
  ])

  //Get name of current axis
  function getCurrentAxis(){
    var name = currentTopics.length ? currentTopics[0].Axis.name : ""
    if (currentTopics.length && currentTopics[0].AxisId === "*"){
      return t("axis_introduction")
    }else if (name){
      return name[props.session.language]
    }else{
      return ""
    }
  }

  //Go to previous questions (reset all responses for one axis)
  //If first question retour to intro page
  function goBack(){
    if (props.session.feedbackId){
      props.history.goBack()
    }else if (props.session.topicsDone.length < 1){
      props.history.push("/intro")
    }else{
      if (props.session.topicsDone.some(x => x.AxisId === props.session.currentAxis)){
        store.dispatch(back(props.session.currentAxis))
      }else{
        props.history.push("/next")
      }
    }
  }

  return (
    <Page backgroundColor={
        currentTopics.length ?
        currentTopics[0].Axis.color :
        undefined
      }
      tabTitle={props.session.surveyName}
    >

      { currentModal === MODAL_NEXT &&
      <SaveModal action="next" onClose={() => setCurrentModal(null)}
        onSaved={() => props.history.push("/next")}
      />
      }

      { props.session.currentQuestionSecondary &&
      <div className={"questions-secondary-background " + css(questionsStyle.fadeIn)}/>
      }

      <div style={{ width : '100%' }}>

        <div className="flex questions-infos">

          <div className="flex1">

            {props.session.messageOptions.displayImage &&
            <div 
              className="intro-image flex"
              style={{
                position: "absolute",
                top:0,
                left:20,
                height: "calc((100vh - 672px) / 2)"
              }}
            >
              <img
                src={props.session.imageUrl}
                style={{
                  height:"100%",
                  objectFit: "contain"
                }}
                alt="intro"
              />
            </div>
            }

            { isMobile &&
            <FontAwesomeIcon icon={faChevronLeft} className="_hover" onClick={goBack}></FontAwesomeIcon>
            }
            
            { (
              props.session.axesCount > 1 && 
              props.session.currentAxis && 
              props.session.currentAxis !== "*"
            ) &&
            <span>
              {`${t("topic")} ${props.session.axesCountDone} / ${props.session.axesCount} : `}
            </span>
            }

            { !props.session.currentAxis &&
            <span>
              {t("topic_empty")}
            </span>
            }

            { !isMobile && getCurrentAxis() }

          </div>

          { !isMobile &&
          <div className="flex4 flex flex-dcol">
            <div className="flex1"/>
            <div style={{ 
                backgroundColor : "white",
                height : 5,
                width : "80%",
                borderRadius : 5,
                margin : "auto"
              }}>
              <div style={{
                backgroundColor: currentTopics.length ? currentTopics[0].Axis.color + "80" : "#8c8c8ccc",
                height : 5, 
                width : `${props.session.topicsDone.length / props.session.topics.length * 100}%`, 
                borderRadius: 5
              }}/>
            </div>
            <div className="flex1"/>
          </div>
          }

          <div className="flex1">
          
            { props.session.currentAxis !== "*" &&
            <span>
              {`${t("question")} ${props.session.topicsDone.length + 1} / ${props.session.topics.length}`}
            </span>
            }

          </div>
          
        </div>
        
        <div className="flex">

          { !isMobile &&
          <div className="flex1 questions-back flex">
            <div className="flex1"></div>
            <FontAwesomeIcon icon={faChevronLeft} className="_hover" onClick={goBack}></FontAwesomeIcon>
          </div>
          }

          { currentTopics.map((topic: Topic) =>
          <div key={topic.id} className="questions-container">
            
            { topic.Questions[0].visible &&
            <QuestionComponent topic={topic}
              isMobile={isMobile}
              isFullScreen={topic.fullScreen && !topic.done}
              question={topic.Questions[0]}
            />
            }
            
            { topic.Questions.filter((x: Question) => x.order > 0 && x.visible).map((question: Question) => 
            <QuestionComponent key={question.id}
              isMobile={isMobile}
              topic={topic}
              isFullScreen={topic.fullScreen && !topic.done}
              question={question}
            />
            )}

          </div>
          )}

          <div className="flex1" />

        </div>

        { !isMobile &&
        <div className="flex questions-infos" />
        }
      
      </div>
      

    </Page>
  )
  
}

const questionsStyle = StyleSheet.create({
  fadeIn : {
    animationName : fadeIn,
    animationDuration : "1s"
  }
})


const mapStateToProps = state => ({
  session : state.reducers
})

export default withRouter(connect(mapStateToProps)(withTranslation()(QuestionsRoute)))